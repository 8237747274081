import React from "react"
import { graphql, Link } from "gatsby"
// import BackgroundImage from "gatsby-background-image"

import Layout from "../../components/layout/basic"
import SEO from "../../components/global/SEO"

// Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import CardDeck from "react-bootstrap/CardDeck"

import BlogCard from "../../components/blog/BlogCard"

const BlogVacancies = ({ data: { vacancies } }) => {
  return (
    <Layout>
      <SEO
        title="Вакансії компанії Новий Телеком"
        description="Працюй в Місцевій Технологічній Компанії - Новий Телеком."
        url="/blog/vakansiyi/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/blog/">Блог</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Вакансії
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">Вакансії від Newtelecom</h1>
          <div className="hero-slogan mb-4">
            Приєднуйтесь до команди інтернет провайдера Новий Телеком!
          </div>
        </Container>
      </div>

      <Container className="my-5">
        <Row>
          <CardDeck as="ul" className="list-unstyled">
            {vacancies.nodes.map(vacancy => (
              <BlogCard
                key={vacancy.id}
                articleImage={vacancy.articleImage.fluid}
                articleTitle={vacancy.articleTitle}
                articlePublishedDate={vacancy.articlePublishedDate}
                articleExcerpt={
                  vacancy.articleExcerpt.childMarkdownRemark.excerpt
                }
                articleSlug={vacancy.seo.slug}
                articleCategories={vacancy.articleCategory}
              />
            ))}
          </CardDeck>
        </Row>
      </Container>
    </Layout>
  )
}

export default BlogVacancies

export const query = graphql`
  {
    vacancies: allContentfulArticle(
      sort: { fields: articlePublishedDate, order: DESC }
      filter: {
        articleCategory: {
          elemMatch: {
            articleCategoryName: { eq: "Вакансії" }
            node_locale: { eq: "uk" }
          }
        }
      }
    ) {
      nodes {
        id
        articleTitle
        articlePublishedDate(formatString: "DD.MM.YYYY")
        articleExcerpt {
          childMarkdownRemark {
            excerpt(pruneLength: 150, truncate: false, format: PLAIN)
          }
        }
        seo {
          slug
          title
          metaDescription
        }
        articleImage {
          title
          description
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        articleCategory {
          articleCategoryName
          id
        }
      }
    }
  }
`
